<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipocarga-modals
			:modelName="modelName"
		></mtipocarga-modals>
	</div>
</template>

<script>
import mtipocargaActions from './MtipocargaActions';
import mtipocargaModals from './MtipocargaModals.vue';

export default {
	name: 'mtipocarga-grid',
	components: {
		'mtipocarga-modals': mtipocargaModals
	},
	data() {
		return {
			modelName: 'mtipocarga',
			actions: mtipocargaActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
